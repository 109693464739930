import BlurImage from "./BlurImage";

type BlogBannerProps = {
  coverImage: { node: { sourceUrl: string } } | undefined;
  slug?: string;
};

export default function BlogBanner({
  coverImage,
  slug = null,
}: BlogBannerProps) {
  const bannerImage = coverImage?.node?.sourceUrl;

  return (
    <>
      <section className="single-header w-100">
        <div className="single-header-img-wrapper">
          <BlurImage
            src={bannerImage}
            alt="banner-img"
            layout="fill"
            priority
          />
        </div>
      </section>
    </>
  );
}
