import Image from "next/image";
// import useNextBlurhash from "use-next-blurhash";

function BlurImage(props) {
  return <Image {...props} />;

  // if (!props.blurhash) {
  //   return <Image {...props} />;
  // }
  
  // const [blurDataUrl] = useNextBlurhash(props.blurhash);
  // return <Image {...props} placeholder="blur" blurDataURL={blurDataUrl} />;
}

export default BlurImage;
