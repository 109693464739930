import React, { useState } from "react";
import MainMenu from "./mainmenu";
import Link from "next/link";
import BlurImage from "../BlurImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
import SearchForm from "../sidebar/SearchForm";

export default function Nav() {
  const [show, setShow] = useState(true);
  const [searchShow, setSearchShow] = useState(false);

  return (
    <>
      <nav className="nav w-100">
        <div className="container">
          <ul>
            <li>
              <Link href="/">
                <a className="nav-bar-logo">
                  <BlurImage
                    style={{ objectFit: "contain" }}
                    width={500}
                    height={70}
                    alt={`Logo`}
                    src="/gossipment-logo.svg"
                  />
                </a>
              </Link>
            </li>

            <li>
              <div style={{ display: "flex" }}>
                {/* Header Search */}
                {!searchShow && (
                  <span
                    onClick={() => setSearchShow(true)}
                    style={{
                      fontSize: "calc(10px + 0.8rem)",
                      padding: " 0.5rem 1rem",
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                )}
                {searchShow && <SearchForm type="header" />}
                {searchShow && (
                  <span
                    onClick={() => setSearchShow(false)}
                    style={{
                      fontSize: "calc(10px + 0.8rem)",
                      padding: " 0.5rem 1rem",
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </span>
                )}
                {/* Header Search Ends */}

                <Link href="#main-menu">
                  <div
                    className="mediaButton nav-icon-v"
                    onClick={() => setShow(!show)}
                  >
                    <span className="span1"></span>
                    <span className="span2"></span>
                    <span className="span3"></span>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <nav
        id="main-menu"
        className={!show ? "hamburger show_list active" : "hamburger"}
      >
        <div className="container">
          <div className="row">
            <Link href="/">
              <a className="hamburger-logo mb-5">
                <BlurImage
                  width={1500}
                  height={220}
                  alt={`Logo`}
                  src="/gossipment-logo.svg"
                />
              </a>
            </Link>
          </div>
        </div>
        <Link href="#">
          <span
            className="mediaButton menu-cross"
            onClick={() => setShow(!show)}
          >
            <svg
              height="512pt"
              viewBox="0 0 512 512"
              width="512pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
              <path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0" />
              <path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0" />
            </svg>
          </span>
        </Link>

        <MainMenu></MainMenu>
      </nav>
    </>
  );
}
