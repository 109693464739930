import React, { useEffect, useState } from "react";

function FooterPWA(props) {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }

  return (
    <div className="pwa-section">
      <div className="sec-title-wrapper">
        <h2 className="sec-title fw-bolder text-uppercase">Install Our PWA</h2>
        <span className="title-box-common"></span>
      </div>
      <span className="content pb-4">
        If you want to ensure that you never miss another update from us,<br></br>
        download the Gossipment app now!
      </span>
      <button
        className="pwa-link-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        Install Now
      </button>
    </div>
  );
}

export default FooterPWA;
