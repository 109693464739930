import BlogBanner from "./BlogBanner";
import Footer from "./Footer/footer";
import Header from "./header/header";
import Nav from "./header/nav";

export default function Layout({
  children = null,
  postType = null,
  blogBanner = null,
  slug = null,
}) {
  const isPost = postType == "post";

  return (
    <div>
      <Header />
      <Nav />
      {isPost && <BlogBanner slug={slug} coverImage={blogBanner} />}
      {isPost && <main className="single-content mb-5">{children}</main>}
      {!isPost && <main>{children}</main>}
      <Footer />
    </div>
  );
}
