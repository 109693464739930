import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function DesktopMenu() {
  return (
    <section className="hamburger-card-desk-top">
      <div className="container ">
        <div className="row">
          <div className="col-md-3">
            <nav className="navigation">
              <ul className="desk-ul">
                <li className="desk-li">
                  <a className="desk-link" href="#celebrity-menu">
                    Celebrities <FontAwesomeIcon icon={faCaretDown} />
                  </a>
                </li>
                <li className="desk-li">
                  <a className="desk-link" href="#entertainment-menu">
                    Entertainment <FontAwesomeIcon icon={faCaretDown} />
                  </a>
                </li>
                <li className="desk-li desk-top-sub">
                  <a className="desk-link" href="#movies-menu">
                    Movies <FontAwesomeIcon icon={faCaretDown} />
                  </a>
                </li>
                <li className="desk-li desk-top-sub">
                  <a className="desk-link" href="#musics-menu">
                    Music <FontAwesomeIcon icon={faCaretDown} />
                  </a>
                </li>
                <li className="desk-li">
                  <a className="desk-link" href="#tv-shows-menu">
                    TV Shows <FontAwesomeIcon icon={faCaretDown} />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-md-9">
            <div id="celebrity-menu" className="sub-menu">
              <span className="menu-item">
                <Link href="/category/celebrities/news/">Celebrity News</Link>
              </span>
            </div>
            <div id="entertainment-menu" className="sub-menu">
              <span className="menu-item">
                <Link href="/category/entertainment/bollywood">Bollywood</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/entertainment/entertainment-news">
                  Entertainment News
                </Link>
              </span>
              <span className="menu-item">
                <Link href="/category/entertainment/hollywood">Hollywood</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/entertainment/television">
                  Television
                </Link>
              </span>
              <span className="menu-item">
                <Link href="/category/entertainment/web-series">
                  Web Series
                </Link>
              </span>
            </div>
            <div id="movies-menu" className="sub-menu">
              <span className="menu-item">
                <Link href="/category/movies/awards/">Awards</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/box-office/">Box Office</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/comedy-movies/">
                  Comedy Movies
                </Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/sci-fi-movies/">Sci-fi</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/thriller-movies/">
                  Thriller Movies
                </Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/movie-reviews/">
                  Movie Reviews
                </Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/movie-news">Movie News</Link>
              </span>
            </div>
            <div id="musics-menu" className="sub-menu">
              <span className="menu-item">
                <Link href="/category/music/artists">Artists</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/music-news">Music News</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/albums">Albums</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/movies/music-review">Music Review</Link>
              </span>
            </div>
            <div id="tv-shows-menu" className="sub-menu">
              <span className="menu-item">
                <Link href="/category/tv-shows/comedy">Comedy</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/tv-shows/crime">Crime</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/tv-shows/drama">Drama</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/tv-shows/family">Family</Link>
              </span>
              <span className="menu-item">
                <Link href="/category/tv-shows/news-tv-shows">
                  News TV Shows
                </Link>
              </span>
              <span className="menu-item">
                <Link href="/category/tv-shows/reality">Reality</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DesktopMenu;
