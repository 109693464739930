import Head from 'next/head'
export default function Header() {

  return (
    <Head>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/favicon.svg" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon.png" />
      <link rel="mask-icon" href="/favicon/favicon.svg" color="#000000" />
      <link rel="shortcut icon" href="/favicon/favicon-ico.ico" />

      <link rel="manifest" href="/manifest.json" />

      <meta name='application-name' content='Gossipment App' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <meta name='apple-mobile-web-app-title' content='Gossipment App' />
      <meta name='format-detection' content='telephone=no' />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='msapplication-config' content='/icons/browserconfig.xml' />
      <meta name='msapplication-TileColor' content='#000000' />
      <meta name='msapplication-tap-highlight' content='no' />
      <meta name='theme-color' content='#000000' />

      <link rel='apple-touch-icon' href='/icons/gossipment-pwa-icon.png' />
      <link rel='apple-touch-icon' sizes='152x152' href='/icons/icon-192x192.png' />
      <link rel='apple-touch-icon' sizes='180x180' href='/icons/icon-192x192.png' />
      <link rel='apple-touch-icon' sizes='167x167' href='/icons/icon-192x192.png' />

      <link rel='manifest' href='/manifest.json' />
      <link rel='mask-icon' href='/icons/gossipment-icon.svg' />
      <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' />

      <meta name="linkdoozer-verification" content="f547d8ed-f172-41b8-9000-f20a89f40d86"/>
      <meta name='linkatomic-verify-code' content='95231e6608dbeb77e439802584bcee4b' />

    </Head>
  )
}
