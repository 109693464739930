import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

function MobileMenu(props) {
  return (
    <section className="hamburger-card-mobile">
      <nav className="animated bounceInDown">
        <ul>
          <li>
            <a href="#celebrity-mobile">
              Celebrities <FontAwesomeIcon icon={faCaretDown} />
            </a>
            <ul id="celebrity-mobile" className="mobile-sub-menu">
              <li>
                <Link href="/category/celebrities/news/">Celebrity News</Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#entertainment-mobile">
              Entertainment <FontAwesomeIcon icon={faCaretDown} />
            </a>
            <ul id="entertainment-mobile" className="mobile-sub-menu">
              <li>
                <Link href="/category/entertainment/bollywood">Bollywood</Link>
              </li>
              <li>
                <Link href="/category/entertainment/entertainment-news">
                  Entertainment News
                </Link>
              </li>
              <li>
                <Link href="/category/entertainment/hollywood">Hollywood</Link>
              </li>
              <li>
                <Link href="/category/entertainment/television">
                  Television
                </Link>
              </li>
              <li>
                <Link href="/category/entertainment/web-series">
                  Web Series
                </Link>
              </li>
            </ul>
          </li>
          <li className="sub-menu">
            <a href="#movies-mobile">
              Movies <FontAwesomeIcon icon={faCaretDown} />
            </a>
            <ul id="movies-mobile" className="mobile-sub-menu">
              <li>
                <Link href="/category/movies/awards/">Awards</Link>
              </li>
              <li>
                <Link href="/category/movies/box-office/">Box Office</Link>
              </li>
              <li>
                <Link href="/category/movies/comedy-movies/">
                  Comedy Movies
                </Link>
              </li>
              <li>
                <Link href="/category/movies/sci-fi-movies/">Sci-fi</Link>
              </li>
              <li>
                <Link href="/category/movies/thriller-movies/">
                  Thriller Movies
                </Link>
              </li>
              <li>
                <Link href="/category/movies/movie-reviews/">
                  Movie Reviews
                </Link>
              </li>
              <li>
                <Link href="/category/movies/movie-news">Movie News</Link>
              </li>
            </ul>
          </li>
          <li className="sub-menu">
            <a href="#music-mobile">
              Music <FontAwesomeIcon icon={faCaretDown} />
            </a>
            <ul id="music-mobile" className="mobile-sub-menu">
              <li>
                <Link href="/category/music/artists">Artists</Link>
              </li>
              <li>
                <Link href="/category/movies/music-news">Music News</Link>
              </li>
              <li>
                <Link href="/category/movies/albums">Albums</Link>
              </li>
              <li>
                <Link href="/category/movies/music-review">Music Review</Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#tv-show-mobile">
              TV Shows <FontAwesomeIcon icon={faCaretDown} />
            </a>
            <ul id="tv-show-mobile" className="mobile-sub-menu">
              <li>
                <Link href="/category/tv-shows/comedy">Comedy</Link>
              </li>
              <li>
                <Link href="/category/tv-shows/crime">Crime</Link>
              </li>
              <li>
                <Link href="/category/tv-shows/drama">Drama</Link>
              </li>
              <li>
                <Link href="/category/tv-shows/family">Family</Link>
              </li>
              <li>
                <Link href="/category/tv-shows/news-tv-shows">
                  News TV Shows
                </Link>
              </li>
              <li>
                <Link href="/category/tv-shows/reality">Reality</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default MobileMenu;
