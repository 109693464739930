import React, { FormEventHandler, useEffect, useState } from "react";
import { useRouter } from "next/router";

function SearchForm({ type = null }) {
  const { query } = useRouter();
  const router = useRouter();

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const searchString = typeof query.s == "object" ? query.s[0] : query.s;
    if (!searchString) {
      const searchString = "";
    }
  }, []);

  const handleFormSubmission: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    router.push(`/?s=${searchString}`);
  };

  return (
    <>
      {/* <p>{JSON.stringify(search)}</p> */}

      <div className={type != 'header' ? "recent-card-sec" : "header-search-form"}>
        <form onSubmit={handleFormSubmission}>
          <div className={type != 'header' ? "formControl" : ""}>
            <input
              type="text"
              value={searchString}
              onChange={(e) => setSearchString(e.currentTarget.value)}
              name="s"
              placeholder="Search here..."
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default SearchForm;
