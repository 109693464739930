import Container from "../container";
import Link from "next/link";
import BlurImage from "../BlurImage";
import InstallPWAButton from "../PWA/InstallPWAbtn";
import FooterPWA from "./FooterPWA";

export default function Footer() {
  return (
    <footer className="footer w-100 pb-5">

      <FooterPWA/>

      <Container>
        <div className="row">
          <div className="col-lg-6">
            <div className="footer-logo-wrapper">
              <Link href="/">
                <BlurImage
                  style={{ filter: "invert(1)" }}
                  width={300}
                  height={44}
                  alt={`Logo`}
                  src="/gossipment-logo.svg"
                />
              </Link>
            </div>
            <div className="footer-content pt-3">
              <p>
                Your one stop destination for latest Page six and entertainment
                content, celeb gossips, latest movies, hottest updates, and
                exclusive stories. Stay tuned with us to get informative, fun
                coverages of spicy celebrity scandals, exotic photos, and
                juciest entertainment tittle-tattles across the world.
              </p>
            </div>
          </div>
          <div className="col-lg-2">
            <h4 className="footer-title mb-3">Important links</h4>
            <ul className="footer-nav">
              <li>
                <Link href="/about-us">About Us</Link>
              </li>
              <li>
                <Link href="/write-for-us">Write For Us</Link>
              </li>
              <li>
                <Link href="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2">
            <h4 className="footer-title mb-3">Categories</h4>
            <ul className="footer-nav">
              <li>
                <Link href="/category/celebrities">Celebrities</Link>
              </li>
              <li>
                <Link href="/category/entertainment">Entertainment</Link>
              </li>
              <li>
                <Link href="/category/movies">Movies</Link>
              </li>
              <li>
                <Link href="/category/music">Music</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2">
            <h4 className="footer-title mb-3">Social Links</h4>
            <ul className="footer-nav">
              <li>
                <Link href="https://www.facebook.com/Gossipment-104612085189662">
                  <a target="_blank" rel="noreferrer nofollow noopener">
                    Facebook
                  </a>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <a target="_blank" rel="noreferrer nofollow noopener">
                    Twitter
                  </a>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <a target="_blank" rel="noreferrer nofollow noopener">
                    Instagram
                  </a>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <a target="_blank" rel="noreferrer nofollow noopener">
                    LinkedIn
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="copy-right">
              <p>
                &#169; 2022 - Gossipment. all Rights Reserved. Designed and
                Developed by
                <Link href="https://www.redhatmedia.net/">
                  <a target="_blank" rel="noreferrer nofollow noopener">
                    {" "}
                    RedHatMedia
                  </a>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
