import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";


export default function MainMenu() {
    
    return (
        <div className="hamburger-wrapper">

            <MobileMenu/>            
            <DesktopMenu/>

        </div>
    );
}